import uniqid from 'uniqid'
import { skills } from '../../portfolio'
import './Skills.css'

const Skills = () => {
  if (!skills.length) return null

  return (
    <section className='section skills' id='skills'>
      <h2 className='section__title'>Skills</h2>
      <p>
        The main area of my expertise is front-end development, HTML, CSS, JS,
        building small and medium web apps, custom plugins, features,
        animations, and coding interactive layouts.
      </p>
      <div className='skills__list'>
        {skills.map((skill) => (
          <div key={uniqid()} className='skills__list-item '>
            <i className={`devicon devicon-${skill}-plain colored`} />
            {skill}
          </div>
        ))}
      </div>
    </section>
  )
}

export default Skills
