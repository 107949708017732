import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from './contexts/theme'
import Header from './components/Header/Header'
import About from './components/About/About'
import Projects from './components/Projects/Projects'
import Skills from './components/Skills/Skills'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import Logo from './components/Logo'
import './App.css'
import SocialIcons from './components/SocialIcons'

const App = () => {
  const [loading, setLoading] = useState(true)
  const [{ themeName }] = useContext(ThemeContext)

  useEffect(() => {
    setTimeout(() => setLoading(false), 2200)
  }, [])

  return (
    <>
      {loading === false ? (
        <div id='top' className={`${themeName} app`}>
          <Header />

          <main>
            <About />
            <Projects />
            <Skills />
            <Contact />
            <Footer />
          </main>

          <SocialIcons />

          <ScrollToTop />
        </div>
      ) : (
        <div className={`${themeName} logo-container`}>
          <Logo />
        </div>
      )}
    </>
  )
}

export default App
