import TypeWriterEffect from 'react-typewriter-effect'
import { about } from '../../portfolio'

import './About.css'

const About = () => {
  const { name, role, description } = about

  return (
    <div className='about center'>
      <div className='about__titles'>
        {name && (
          <h1 style={{ marginTop: '1em' }}>
            <TypeWriterEffect
              text='Hi, I am'
              typeSpeed={100}
              hideCursorAfterText
            />

            <TypeWriterEffect
              textStyle={{ color: 'var(--clr-primary)' }}
              text={name}
              typeSpeed={100}
              startDelay={1000}
              hideCursorAfterText
            />
          </h1>
        )}

        {role && (
          <h2 className='about__role'>
            <TypeWriterEffect
              textStyle={{ fontSize: '2.5rem' }}
              text={role}
              typeSpeed={100}
              startDelay={2100}
              hideCursorAfterText
            />
          </h2>
        )}
      </div>
      <div className='about__desc'>
        <p>{description && description}</p>
        <div className='about__buttons'>
          <a href='#contact' className='btn btn--plain'>
            Contact me
          </a>
          <a
            href='/1/CV_Carlos_Costa.pdf'
            className='btn btn--outline'
            download
          >
            Download CV
          </a>
        </div>
      </div>
    </div>
  )
}

export default About
