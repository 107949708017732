import uniqid from 'uniqid'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkIcon from '@material-ui/icons/Link'
import './ProjectContainer.css'

const ProjectContainer = ({ project }) => (
  <div className='projects__grid'>
    <img src={project.cover} alt='project' />
    <div className='project'>
      <h3>{project.name}</h3>

      <p className='project__description'>{project.description}</p>

      {project.stack && (
        <ul className='project__stack'>
          {project.stack.map((item) => (
            <li key={uniqid()} className='project__stack-item'>
              {item}
            </li>
          ))}
        </ul>
      )}

      {project.sourceCode && (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={project.sourceCode}
          aria-label='source code'
          className='link link--icon'
        >
          <GitHubIcon />
        </a>
      )}

      {project.link && (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={project.link}
          aria-label='link'
          className='link link--icon'
        >
          <LinkIcon />
        </a>
      )}
    </div>
  </div>
)

export default ProjectContainer
