const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '#top',
  title: 'CC',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Carlos Costa',
  role: 'Frontend Developer',
  description:
    "My name is Carlos Costa and I´m a Fullstack developer at Frejen Wise Solutions. I'm an curious and self-motivated person with a passion for programming. Interested in devising a better problem-solving method for challenging tasks, and learning new technologies and tools.",
  social: {
    linkedin: 'https://www.linkedin.com/in/carlos-costa-3aa726193/',
    github: 'https://github.com/Carlos366',
  },
}

const projects = [
  {
    name: 'EventMixer Platform',
    description:
      'The eventmixer project is a collaborative platform for editing audiovisual content, referring to a specific event, accessible to users with limited technical knowledge in the area.',
    stack: ['Bootstrap', 'React-router', 'React'],
    sourceCode: 'https://github.com/Carlos366/eventmixer',
    cover: '/1/event.webp',
  },
  {
    name: 'Gamepad Platform',
    description:
      'This project consisted in a website in which the user created an account and had access to detailed information about games and could also add them to a list of favorites.',
    stack: ['Bootstrap', 'Firebase', 'React', 'Redux', 'React-router'],
    sourceCode: 'https://github.com/Carlos366/Gamepad',
    cover: '/1/gamepad.webp',
    link: 'https://gamepadplatform.netlify.app/',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'html5',
  'css3',
  'javascript',
  'typescript',
  'react',
  'redux',
  'sass',
  'bootstrap',
  'nodejs',
  'gatsby',
  'jquery',
  'git',
]

export { header, about, projects, skills }
