import './Contact.css'
import { useState } from 'react'
import LocalPhone from '@material-ui/icons/LocalPhone'
import EmailIcon from '@material-ui/icons/Email'
import PlaceIcon from '@material-ui/icons/Place'
import emailjs from 'emailjs-com'

const Contact = () => {
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const submit = () => {
    if (name && email && message && subject) {
      const serviceId = 'service_qh1q473'
      const templateId = 'template_eou2klb'
      const userId = 'user_jy6wbYBL3VMcNmkTb4TdG'
      const templateParams = {
        name,
        email,
        message,
        subject,
      }

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => console.log(response))
        .then((error) => console.log(error))

      setName('')
      setSubject('')
      setEmail('')
      setMessage('')
      setEmailSent(true)
    } else {
      // eslint-disable-next-line
      alert('Please fill in all fields.')
    }
  }
  return (
    <section className='section contact center' id='contact'>
      <h2 className='section__title'>Contact</h2>
      <div className='contact__content'>
        <div className='right'>
          <h2 className='contact__title'>Get in touch</h2>
          <div id='contact-form'>
            <input
              type='text'
              placeholder='Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type='text'
              placeholder='Subject'
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <input
              type='email'
              placeholder='Your email address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              rows='5'
              placeholder='Your message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={submit} type='submit' className='btn btn--plain'>
              Submit
            </button>
            <span style={{ display: emailSent ? 'block' : 'none' }}>
              Thank you for your message, we will be in touch in no time!
            </span>
          </div>
        </div>
        <div className='left'>
          <h2 className='contact__title'>Contact Info</h2>
          <div className='info'>
            <div className='info__item'>
              <LocalPhone />
              &nbsp;&nbsp; +351 913384954
            </div>
            <div className='info__item'>
              <EmailIcon />
              &nbsp;&nbsp; carloscosta307@gmail.com
            </div>
            <div className='info__item'>
              <PlaceIcon />
              &nbsp;&nbsp; Rua do Cardal nº120, Bente, Vila Nova de Famalicão
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
