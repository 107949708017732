import './index.css'

const Logo = () => (
  <div className='logo'>
    <svg
      id='logo'
      data-name='logo'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 468 728'
      transform='scale(0.3)'
    >
      <path
        className='path'
        fill='none'
        stroke='#922724'
        strokeMiterlimit='10'
        strokeWidth='4px'
        d='M210.36,146.12a67.19,67.19,0,0,0-11.74,8.81c5.37-3.39,11-5.75,17-5.75q23.82,0,41.1,12.16t27.23,37.2c6.64,16.7,10.94,23.86,12.89,49.47h39q-2.93-38.41-12.89-63.47t-27.23-37.2q-17.26-12.15-41.1-12.16Q228.83,135.18,210.36,146.12Z'
      />
      <path
        className='path'
        fill='none'
        stroke='#922724'
        strokeMiterlimit='10'
        strokeWidth='4px'
        d='M139.84,703q-47.67-26-72.95-75.63T41.6,507.22V229.53q0-70.51,25.29-120.37t73-75.87q47.66-26,114.77-26c1.15,0,2.28,0,3.42.05a284.67,284.67,0,0,0-42.42-3.05q-67.11,0-114.77,26T27.89,106.16Q2.6,156,2.6,226.53V504.22q0,70.51,25.29,120.12t73,75.63q46,25.13,110.25,26Q171.25,720.08,139.84,703Z'
      />
      <path
        className='path'
        fill='none'
        stroke='#922724'
        strokeMiterlimit='10'
        strokeWidth='4px'
        d='M257,585.92A65.13,65.13,0,0,1,227,597.35a96.7,96.7,0,0,0,27.57,3.73q24.31,0,41.34-12.16t27.24-37.2q10.2-25.05,12.64-63.47h-33C301.2,513.87,291,532,284.19,548.72S268.3,577.82,257,585.92Z'
      />
      <path
        className='path'
        fill='none'
        stroke='#922724'
        strokeMiterlimit='10'
        strokeWidth='4px'
        d='M140.48,703.12q-47.67-26-72.95-75.62T42.24,507.37V229.68q0-70.52,25.29-120.37t72.95-75.86q47.66-26,114.77-26,66.13,0,111.37,26.26t70.28,79.52q25,53.25,29.91,135H336.47q-2.93-38.41-12.89-63.46t-27.23-37.21q-17.27-12.15-41.1-12.16-25.78,0-44.25,10.95t-28.45,32.34q-10,21.4-10,51.06V507.37q0,30.17,10,51.07T211,590.29q18.47,11,44.25,11,24.31,0,41.34-12.16t27.24-37.21q10.2-25,12.64-63.46H466.81q-5.37,81.7-30.4,135t-70,79.52q-45,26.26-111.13,26.26Q188.14,729.14,140.48,703.12Z'
      />
    </svg>
  </div>
)

export default Logo
