import React from 'react'
import './style.css'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { about } from '../../portfolio'

const SocialIcons = () => {
  const { social } = about
  return (
    <div className='stick_follow_icon'>
      <ul>
        {social.github && (
          <li>
            <a href={social.github}>
              <GitHubIcon />
            </a>
          </li>
        )}
        {social.linkedin && (
          <li>
            <a href={social.linkedin}>
              <LinkedInIcon />
            </a>
          </li>
        )}
      </ul>
      <p>Follow Me</p>
    </div>
  )
}

export default SocialIcons
